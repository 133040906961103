import {
  Center,
  Container,
  VStack,
  HStack,
  Heading,
  Text,
  SimpleGrid,
  Link,
  Box,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { TextUnderline } from '../components/atoms';
import { Footer, Header } from '../components/molecules';
import { AiFillContainer } from 'react-icons/ai';
import { BiTable } from 'react-icons/bi';
import Loader from '../components/molecules/Loader';

const resourcesList = [
  {
    id: 1,
    name: 'Incoterms 2020 Table',
    desc: '',
    link: '/incoterms',
    icon: (
      <BiTable
        style={{
          width: '4em',
          height: '4em',
          marginBottom: '1em',
          color: '#213A71',
        }}
      />
    ),
  },
  {
    id: 2,
    name: 'Container Specifications',
    desc: '',
    link: '/seafreight',
    icon: (
      <AiFillContainer
        style={{
          width: '4em',
          height: '4em',
          marginBottom: '1em',
          color: '#213A71',
        }}
      />
    ),
  },
  {
    id: 3,
    name: 'Seaport and Airport Codes',
    desc: '',
    link: '/search',
    icon: '/resources/icons/Resources _ GFL Group of Companies-4-01.svg',
  },
  {
    id: 4,
    name: 'Glossary of Shipping Term',
    desc: '',
    link: '/search',
    icon: '/resources/icons/Resources _ GFL Group of Companies-5-01.svg',
  },
  {
    id: 5,
    name: 'Federation of Malaysian Freight Forwarder (FMFF)',
    desc: '',
    link: 'https://fmff.net/ ',
    icon: '/resources/icons/FMFFLogo.png',
  },
  {
    id: 6,
    name: 'FMFF Standard Trading Conditions (STC)',
    desc: '',
    link: 'https://fmff.net/wp-content/uploads/2021/05/FMFF-STC-Final-24042021-3COL-1.pdf ',
    icon: '/resources/icons/FMFFLogo.png',
  },
];

const Resources = () => {
  return (
    <>
      <Helmet>
        <title>Resources | GFL Group of Companies</title>

        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="icon"
        ></link>
        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="apple-touch-icon"
        ></link>

        <meta
          content="width=device-width, initial-scale=1.0"
          name="viewport"
        ></meta>
        <meta
          content="logistics, suppliers, shipping, warehousing, malaysian, malaysia, klang, freight, air freight, ship freight, rail freight, cargo, cargo express"
          name="keywords"
        />
        <meta
          name="description"
          content="Your solution to total cargo transport"
        />
        <meta name="robots" content="index, follow"></meta>
        <link rel="canonical" href="https://www.gflgroup.com.my" />
        <meta
          property="og:title"
          content="Resources - GFL | Your solution to total cargo transport"
        ></meta>
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:description"
          content="Your solution to total cargo transport"
        ></meta>
        <meta property="og:url" content="https://www.gflgroup.com.my"></meta>
        <meta
          property="og:site_name"
          content="Resources - GFL | Your solution to total cargo transport"
        ></meta>
        <meta
          property="og:image"
          content="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
        ></meta>
        <meta property="twitter:card" content="summary large image"></meta>
        <meta
          property="twitter:label1"
          content="Your solution to total cargo transport"
        ></meta>
      </Helmet>
      <Loader /> <Header />
      <VStack spacing={5} alignItems={'center'} w="100%" pb="2rem">
        <HStack
          w="100%"
          h={['20vh', '50vh', '70vh']}
          backgroundAttachment={'unset'}
          backgroundRepeat="no-repeat"
          backgroundImage={'/resources/resources-banner.jpg'}
          backgroundPosition="0% center"
          position="sticky"
          backgroundSize={['145%', 'cover', 'cover']}
          justifyContent="center"
          pl={['1.5rem', '3rem', '6rem']}
        >
          <VStack w="100%" alignItems={'flex-start'} zIndex="4">
            <Heading
              fontSize={['1rem', '2.5rem', '3rem']}
              color="rgb(255, 255, 255)"
              className="title-animation"
            >
              Resources
            </Heading>
            <TextUnderline color="#ffffff" opacity=".8" />
          </VStack>
        </HStack>

        <Container maxW="container.xl" w="100%" mt="3em !important">
          <VStack w="100%">
            <Text>Below is some information which you may find it useful</Text>

            <VStack
              w="100%"
              alignItems={'center'}
              justifyContent={'flex-start'}
              mt="3em !important"
            >
              <Heading as="h2" fontSize={'1.8rem'}>
                Useful Links
              </Heading>
              <HStack
                style={{
                  marginTop: '0.8em',
                }}
                backgroundColor="#213A71"
                w="10%"
                h="5px"
              ></HStack>
              <SimpleGrid
                columns={[1, 1, 2]}
                spacing={14}
                w="100%"
                mt="3em !important"
              >
                {resourcesList.map((item) => (
                  <Link
                    className="documents-wrapper"
                    href={item.link}
                    target={'_blank'}
                    height={'100%'}
                  >
                    <VStack
                      bgColor={'whte'}
                      borderRadius={'8px'}
                      p={5}
                      alignItems={'flex-start'}
                      key={item.id}
                      cursor={'pointer'}
                      position={'relative'}
                      background={'#E3E8F9'}
                      className="documents-card"
                    >
                      {/* <Box
                          className="documents-card-cta"
                          w="50px"
                          h="100px"
                          borderLeftRadius={'50%'}
                          background={'#1843A040'}
                          d="flex"
                          justifyContent={'center'}
                          alignItems={'center'}
                          position={'absolute'}
                          top="50%"
                          right={'0'}
                          transform={'translateY(-50%)'}
                          transition={'all .3s ease-in-out'}
                        >
                          <BsArrowRight
                            style={{
                              marginLeft: '0.5em',
                              color: 'white',
                            }}
                          />
                        </Box> */}

                      <Box
                        className="documents-card-cta"
                        w="50px"
                        h="50px"
                        borderRadius={'50%'}
                        background={'#1843A015'}
                        d="flex"
                        justifyContent={'center'}
                        alignItems={'center'}
                        position={'absolute'}
                        top="50%"
                        right={'40px'}
                        transform={'translateY(-50%)'}
                        transition={'all .3s ease-in-out'}
                      >
                        <BsArrowRight
                          style={{
                            color: 'white',
                          }}
                        />
                      </Box>

                      {typeof item.icon === 'string' ? (
                        <img
                          style={{ height: '64px', width: '64px' }}
                          src={item.icon}
                        />
                      ) : (
                        item.icon
                      )}

                      <Heading
                        as="h3"
                        fontSize={'1.2rem'}
                        d="flex"
                        alignItems={'flex-end'}
                        cursor="pointer"
                      >
                        {item.name}
                      </Heading>
                      <Text>{item.desc}</Text>
                    </VStack>
                  </Link>
                ))}
              </SimpleGrid>
            </VStack>
          </VStack>
        </Container>
      </VStack>
      <Footer />
    </>
  );
};

export default Resources;
